.input {
  font-size: 1rem;
  font-family: inherit;
  padding: 0.5rem;
  border-radius: 8px;
  border: none;
  outline: none;

  background: var(--light-blue);
  resize: none;
	
	transition: 0.08s ease;
}

.input::placeholder {
  color: var(--dark-blue);
}

.input:disabled {
	background: var(--blue-gray);
	cursor: not-allowed;
}

textarea.input {
  padding: 1rem;
}

.dark {
  background: var(--blue);
}

.mini {
  width: calc(1rem + 3rem);
  text-align: center;
}

.normal {
  padding: 1rem;
}

.big {
  padding: 1.5rem;
}

.textarea {
  width: 100%;
  min-height: 8rem;
}

.wide{
  width: 100%;
}

/* Buttons */
.button{
	--buttonAccentColor: var(--neutralAccentColor);
	color: white;
	border: none;
	background-color: var(--buttonAccentColor);
	padding: 15px 30px;
	border-radius: var(--smallRadius);
	cursor: pointer;
	outline: none !important;
	font-size: 18px;
	font-family: inherit;
	font-weight: bold;
}

.button.plain{
	color: inherit;
	background-color: transparent;
	font-size: inherit;
	padding: 0;
}

.button.plain.positive,
.button.plain.negative,
.button.plain.neutral{
	color: var(--buttonAccentColor);
}

.button:hover{
	filter: brightness(115%);
}

.button:active{
	filter: brightness(95%);
}

button.positive{
	--buttonAccentColor: var(--positiveAccentColor);
}

button.negative{
	--buttonAccentColor: var(--negativeAccentColor);
}

.button.hollow{
	border: 1px solid var(--buttonAccentColor);
	background-color: inherit;
	color: var(--buttonAccentColor);
}

.button:disabled{
	cursor: default;
	filter: grayscale(70%) brightness(80%) opacity(80%);
}

.button.cion{
	font-weight: bold;
	background: linear-gradient(90deg, rgba(234,35,95,1) 0%, rgba(242,97,75,1) 100%);
}

.wide{
	width: 100%;
	min-width: 100%;
	max-width: 100%;
}

span.wide, label.wide{
	display: block;
}

textarea.input{
	min-height: 40px;
	font-family: inherit;
}

div.line{
	padding: 0;
	margin: 10px 0;
	border-top: 1px solid var(--borderColor);
}
