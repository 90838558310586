.mobileLinks {
	display: none;
}

.links {
	display: flex;
	gap: 3rem;
	padding: 0.75rem 0;
	width: 100%;
}

.links > * {
	width: 100%;
	align-self: center;
}

.buttons {
	display: flex;
	justify-content: flex-end;
}

@media (max-width: 1280px) {
	.links {
		justify-self: flex-end;
		gap: 1.5rem;
	}
}

@media (max-width: 680px) {
	.buttons {
		justify-content: center;
		align-self: center;
	}
	
	.mobileLinks {
		width: 100%;
		display: flex;
		justify-content: space-around;
	}

	.mobileRow {
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		align-items: center;
		gap: 1.5rem;
	}

	.mobileRow .buttons {
		align-self: flex-end;
	}
	
	.links:not(.expanded) {
		display: none;
	}

	.links {
		margin-top: 2rem;
	}

	.links {
		flex-direction: column;
		align-self: center;
	}
}
