:root {
	--highlight: #FBC0B1;
	--body-text: #4E575E;
	--light-gray: #DEDFE0;
	--light-blue: #EDF2F6;
	--blue: #dce8f2;
	--blue-gray: #cbd9e7;
	--dark-blue: #A8B6C4;
	--transition-pink-blue: #C2D3E1, #FDC0B0;
}

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;1,300;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;1,200;1,300;1,400;1,600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Pangolin&display=swap');

html,
body {
	width: 100%;
	height: 100%;
	padding: 0;
	margin: 0;
	color: var(--body-text);
	font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, sans-serif;
	scroll-behavior: smooth;
}

h1, h2, h3, h4, h5, h6, a {
	font-family: 'Nunito', sans-serif;
}

.appWrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
}

body {
	background: url("./assets/section-background.png") center center no-repeat fixed;
	background-size: cover;
}

a {
	color: inherit;
	text-decoration: none;
}

* {
	box-sizing: border-box;
}

.limited {
	width: 100%;
	max-width: 1680px;
	margin: 0 auto;
}

.limited-md {
	width: 100%;
	max-width: 1420px;
	margin: 0 auto;
}

main,
.hero {
	width: 100%;
}

.hero {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
}

.hero,
.viewport-100 {
	height: 100%;
	max-height: 1075px;
}

.hero-content {
	min-height: 300px;
	position: relative;
	display: flex;
	justify-content: space-between;
}

.title {
	margin: 3rem 0;
	margin-top: 0;
}

.page-heading {
	font-size: 3rem;
	font-weight: 700;
}

h1, h2 {
	font-weight: 500;
}

h1 {
	font-size: 4rem;
	margin: 1rem 0;
	color: var(--highlight);
}

h2 {
	font-size: 2rem;
	margin: 0;
}

h3 {
	font-size: 1.75rem;
	font-weight: 500;
}

.benefits {
	list-style: none;
	padding: 0;
	margin: 0;

	font-size: 1.25rem;
}

.benefits > li {
	margin: 1rem 0;
}

.benefits > li:first-child {
	margin-top: 0;
}

.benefits > li:last-child {
	margin-bottom: 0;
}

/* Treat Animation */

.treat-internal {
	transition: filter 1s ease-in-out;

	animation: 6s bob infinite ease-in-out;
}

.delay-1 {
	animation-delay: -1.5s;
}

.delay-2 {
	animation-delay: -3s;
}

.delay-3 {
	animation-delay: -4.5s;
}

.treat-image {
	animation: rotate linear infinite;
}

.delay-1 .treat-image {
	animation-delay: -8s;
	animation-duration: 160s;
}

.delay-2 .treat-image {
	animation-delay: -12s;
	animation-duration: 80s;
	animation-direction: reverse;
}

.delay-3 .treat-image {
	animation-delay: -16s;
	animation-duration: 100s;
	animation-direction: reverse;
}

.section-heading {
  font-size: 1.5rem;
	font-weight: 700;
  margin-bottom: 1.5rem;
}

.section-subheading {
	font-size: 1.25rem;
  margin: 1.25rem 0;
	font-weight: 700;
}

@keyframes bob {
	0% {
		filter: drop-shadow(6px 18px 16px #0303033D);
		transform: translateY(0);
	}
	50% {
		filter: drop-shadow(6px 4px 6px #0303033D);
		transform: translateY(12px);
	}
	100% {
		filter: drop-shadow(6px 18px 16px #0303033D);
		transform: translateY(0);
	}
}

@keyframes rotate {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(359deg);
	}
}

.centerLoading {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
}
