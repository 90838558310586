.container {
  background: var(--blue);
  padding: 2rem;
  border-radius: 0.5rem;
}

.heading {
  font-size: 1.4rem;
  margin-bottom: 1rem;
}

.grid {
  display: grid;
  grid-template-columns: repeat(5, minmax(auto, 1fr));
  grid-template-rows: auto;
  gap: 1rem;
}

:global(.mobile) .grid {
  display: flex;
  flex-direction: column;
}

:global(.mobile) .grid > * {
  font-size: 1rem;
}

.error {
	font-size: 0.75rem;
	margin-bottom: 1.5rem;
	color: #ff4141;
}
