.popupBG{
	position: fixed;
	background-color: rgba(32, 32, 49, 0.3);
	min-height: 100vh;
	width: 100vw;
	backdrop-filter: blur(10px);
	cursor: pointer;
	padding: 32px;

	max-height: 100vh;
	overflow-y: auto;
	display: flex;
	align-items: center;
	justify-content: center;
}

.Popup{
	background-color: #EDF2F6;
	border-radius: 20px;
	cursor: auto;
	padding: 20px;
}

.Popup {
	max-height: 90vh;
	overflow: auto;
}

.Popup h1.popTitle {
	font-size: 33pt;
	color: #60686E;
}

.Popup .titleBar {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px 10px 25px 10px;
	gap: 25px;
	border-bottom: 2px solid #C4C9CF;
}

.Popup h1:empty{
	display: none;
}

.Popup h1{
	margin: 0;
}

.Popup .exitIcon {
	font-size: 35px;
	color: #A2A2AF;
}

.Popup .story{
	padding: 10px 0;
}

.Popup .story:empty{
	display: none;
}

.mobile .popupBG{
	padding: 0;
}

.mobile .Popup{
	height: 100%;
	max-height: unset;
	
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	overflow-y: auto;
	border-radius: 0;

	padding: 20px;
}
