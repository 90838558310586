.container {
  padding: 2rem;
  font-size: 8rem;
}

.container img {
  max-width:  500px;
  object-fit: contain;
}

.text,
.offsetText {
	display: flex;
	justify-content: center;
	align-items: center;
	
  position: absolute;
  inset: 0;
	margin: 0;

  font-family: 'Pangolin';
  text-align: center;
}

.text {
  z-index: 3;
}

.offsetText {
  z-index: 2;
  transform: translate(4px, 4px);
  filter: brightness(0.7);
}
