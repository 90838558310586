.container {
  position: relative;

  padding: 0.75rem;
  font-size: 1.25rem;
  
  background: var(--light-blue);
  border-radius: 8px;

  user-select: none;
}

.preview {
  margin-right: 2.5rem;
}

.container.open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.icon {
  position: absolute;
  right: 1rem;
  top: 0;
  bottom: 0;

  margin: auto;

  transition: 0.16s ease;
}

.container.open .icon {
  transform: rotate(180deg);
  transform-origin: center 50%;
}

.container:hover {
  cursor: pointer;
}

.content {
  position: absolute;

  left: 0;
  right: 0;
  bottom: 0;

  padding: 0.25rem;
  border-radius: 0 0 8px 8px;

  transform: translateY(100%);
  background: var(--light-blue);
  filter: brightness(0.975);
  
  max-height: 400px;
  overflow-y: auto;
}

.option {
  margin: 0.125rem;
  padding: 0.5rem;
}

.option:hover {
  cursor: pointer;
  filter: brightness(0.965);
  background: inherit;
  border-radius: 4px;
}
