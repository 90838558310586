.row {
  display: flex;
  justify-content: space-between;

  font-size: 1.5rem;
}

.row:not(:first-child) {
  margin-top: 0.5rem;
}

.value {
  font-weight: 700;
}
