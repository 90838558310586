.container {
  flex-grow: 1;

  padding: 2rem 0;
}

:global(.mobile).container {
  margin-top: 6rem;
}

@media (max-width: 680px) {
  :global(.mobile).container {
    margin-top: 0rem;
  }
}


.split {
  display: grid;
  grid-template-columns: 65% 1fr;
  gap: 2rem;
	padding: 0 2rem;
}

.nextButton {
  margin-top: 1rem;
  max-width: 220px;
}

:global(.mobile) .nextButton {
  width: 100%;
  max-width: 100%;
}

:global(.mobile) .nextButton > a {
  min-width: 100%;
}

:global(.mobile) .split {
  display: flex;
  flex-direction: column;
  min-width: 100vw !important;
}
