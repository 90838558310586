.container:not(:first-child) {
  margin-top: 2rem;
}

.heading {
  margin-bottom: 1.5rem;
  font-size: 2rem;
  font-weight: 700;
}

.items {
  display: flex;
  flex-wrap: wrap;

  gap: 1.5rem;
  row-gap: 4rem;

  width: 100%;
}

:global(.mobile) .items {
  row-gap: 1rem;
  justify-content: center;
}
