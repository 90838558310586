.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

:global(.mobile).container {
  margin-top: 6rem;
}

@media (max-width: 680px) {
  :global(.mobile).container {
    margin-top: 0rem;
  }
}


.breadcrumb {
  align-self: flex-start;
}

.deliveryInfoContainer {
  padding: 8rem 2rem;
}

:global(.mobile) .deliveryInfoContainer {
  padding: 6rem 2rem;
}

:global(.mobile) .deliveryInfoContainer {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  width: 100%;
}

.inputRow {
  display: flex;
  padding: 1rem 0;
  gap: 1rem;
}

:global(.mobile) .inputRow {
  display: flex;
  flex-direction: column;
  width: 100%;
}

:global(.mobile) .inputRow > * {
  display: flex;
  flex-direction: column;
  width: 100%;
}



.nextContainer {
  margin-top: 1rem;
  max-width: 200px;
}


:global(.mobile) .nextContainer, :global(.mobile) .nextContainer > * {
  max-width: 100%;
  width: 100%;
}

.issueText {
	margin-top: 0.75rem;
}

.issueText,
.issueList {
	font-size: 0.75rem;
	color: #ff4141;
}

ul.issue-list {

}

.deliveryWindow > div {
  font-size: 1rem !important;
  padding: 1rem;
  color: black;
}

.deliveryWindow{
  width: 33% !important;
}

:global(.mobile) .deliveryWindow{
  width: 100% !important;
}
