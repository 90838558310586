.container {
  display: flex;
  align-items: center;

  font-size: 1.15rem;
  padding: 0 2rem;

  margin: 2rem 0;
}

.crumb:not(:last-child)::after {
  content: '>';
  margin: 0 1rem;
}
