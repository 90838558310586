.container {
  flex-grow: 1;

  padding: 2rem 0;
}

.split {
  display: grid;
  grid-template-columns: 65% 1fr;
  gap: 2rem;
	padding: 0 2rem;
}

.quantityDisplay {
	margin-bottom: 0.75rem;
}

.customerInfo {
	display: grid;
	grid-template-columns: auto auto;
	grid-template-rows: auto auto;

	padding: 4rem;
	gap: 2rem;
}

.heading {
	font-size: 2.5rem;
	margin: auto;
	align-self: center;
	margin-bottom: 4rem;
}

.content {
	margin: auto;
	
	max-width: 1000px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	padding: 2rem;

	line-height: 1.75em;
	font-size: 1.25rem;
}

.anchorContainer {
	margin-top: 2rem;
	width: 100%;
	display: flex;
	justify-content: center;
}

.toHome {
	width: 16rem;
	
	justify-self: center;
}


:global(.mobile) .anchorContainer, :global(.mobile) .anchorContainer > * {
	width: 100%;
	max-width: 100%;
}
