.container {
	width: 100%;
	padding: 2rem; 

	display: flex;
	justify-content: center;
	align-items: center;
	
	position: fixed;
	z-index: 1;
	top: 0;
	left: 0;
	right: 0;

	margin: 0 auto;

	background: rgba(255, 255, 255, 0.5);
	backdrop-filter: blur(4px);
	-webkit-backdrop-filter: blur(4px);
}

@supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
	.container {
		background: rgba(255, 255, 255, 0.9);
	}
}

.external {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}

.hamburger {
	display: none;

	outline: none;
	border: none;
	background: url('../../assets/hamburger.svg') no-repeat center center;
	background-size: contain;
	cursor: pointer;
}

.logo {
	min-width: 200px;
	height: 80px;
	background: url('../../assets/logo-full.png') no-repeat left center;
	background-size: contain;
}

.internal {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

@media (max-width: 978px) {	
	.external {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 2rem;
	}

	.internal {
		width: 100%;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.hamburger {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		margin: auto;

		height: 2rem;
		width: 2rem;
		display: none;
	}
	
	.logo {
		background: url('../../assets/logo-single.png') no-repeat center center;
		background-size: contain;
	}
}

@media (max-width: 680px) {
	.hamburger {
		display: block;
	}
}
