.container {
  display: flex;
  align-items: space-between;
  align-items: center;
}

.image {
  width: 3rem;
  height: 3rem;
  border-radius: 0.5rem;
  margin-right: 0.5rem;

  object-fit: cover;
}

.price {
  font-weight: 700;
}

.itemInfo {
  display: flex;
}

.removeBtn{
  flex-grow: 1;
  text-align: right;
  transition: 0.3s;
}


.removeBtn:hover{
  cursor: pointer;
  color: #8f3924;
}

.options {
	margin: 0.25rem 0;
	font-size: 0.75rem;
}
