.container {
  flex-grow: 1;

  padding: 2rem 0;
}

:global(.mobile).container {
  margin-top: 6rem;
}

@media (max-width: 680px) {
  :global(.mobile).container {
    margin-top: 0rem;
  }
}



.split {
  display: grid;
  grid-template-columns: 65% 1fr;
  gap: 2rem;
	padding: 0 2rem;
}



:global(.mobile) .split {
  display: flex;
  flex-direction: column;
  min-width: 100vw !important;
}


.quantityDisplay {
	margin-bottom: 0.75rem;
}

.customerInfo {
	display: grid;
	grid-template-columns: auto auto;
	grid-template-rows: auto auto;

	padding: 4rem;
	gap: 2rem;
}


:global(.mobile) .customerInfo {
	display: flex;
	flex-direction: column;
	width: 100%;
	padding-left: 0;
	padding-bottom: 1rem;
}
