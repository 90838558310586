.container {
  width: 100%;
}

.cart {
  padding: 1rem;
  border-radius: 0.5rem;

  background: var(--blue);

	display: flex;
	flex-direction: column;
	gap: 0.5rem;
}

.total {
	margin-top: 2rem;
	display: flex;
	flex-direction: column;
}

.row:not(:last-child) {
	margin-bottom: 0.25rem;
}

.row {
	display: flex;
	justify-content: space-between;
}

.dataValue {
	font-weight: 700;
}

.submitButton {
	margin-top: 6rem;
}

:global(.mobile) .submitButton, :global(.mobile) .submitButton > * {
	margin-top: 2rem;
	width: 100%;
	min-width: 100%;
}

.emptyState {
	padding: 1rem;
	display: flex;
	justify-content: center;
	align-items: center;

	font-size: 1.1rem;
	opacity: 0.8;
}


.errorMsg {
	color: red;
	margin-top: 0.5rem;
}
