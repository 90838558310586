.container {
  flex-grow: 1;
}


:global(.mobile).container {
  margin-top: 8rem;
}

@media (max-width: 680px) {
  :global(.mobile).container {
    margin-top: 0rem;
  }
}


.builder {
  display: flex;
  width: 100%;

  padding: 4rem 2rem;
}

.options {
  flex-grow: 1;
}

.row {
  display: flex;
  gap: 1.5rem;
}

:global(.mobile) .row {
  flex-direction: column;
}


.row:not(:first-child) {
  margin-top: 1.5rem;
}

.row > * {
  flex-grow: 1;
}

.cookieContainer {
  align-self: flex-start;
  position: sticky;
  top: 10rem;
  
  margin-right: 2rem;
}

.totalContainer {
  display: flex;
  padding: 3rem 0;
  justify-content: flex-end;
}

.lettering {
  display: flex;
	flex-direction: column;
	gap: 1rem;
}

.flavours {
	flex-grow: 1;
	display: grid;
	grid-template-columns: repeat(4, auto);
}

:global(.mobile) .flavours {
  display: flex;
  flex-direction: column;
}

:global(.mobile) .flavours > * {
  margin: 0.3rem 0;
  font-size: 16px;
}

.internalGroup {
  display: flex;
  align-items: center;
  gap: 1rem;
}

:global(.mobile) .internalGroup {
  display: flex;
  flex-direction: column;
  text-align: left;
}

:global(.mobile) .internalGroup > * {
  align-self: flex-start;
  margin: 0.1rem 0;
}

:global(.mobile) .internalGroup > input {
  width: 100%;
}

.internalTitle {
  font-size: 1.35rem;
}

.submitButton {
	margin-left: auto;
	width: fit-content;
}

@media (max-width: 1320px) {
  .cookieContainer {
    position: relative;
    max-width: 300px;
    margin-right: 0;

    align-self: unset;
    top: unset;
  }

  .cookieContainer img {
    max-width: 100%;
  }

  .builder {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .options {
    width: 100%;
    overflow-y: auto;
  }
}

:global(.mobile) .totalContainer, :global(.mobile) .totalContainer > * {
  width: 100% !important;
  justify-content: flex-start;
}


:global(.mobile) .submitButton, :global(.mobile) .submitButton > * {
  width: 100% !important;
  min-width: 100%;
  /* justify-content: flex-start; */
}

.error {
	max-width: 64ch;
	text-align: right;
	margin-left: auto;

	margin-bottom: 1.5rem;

	font-size: 0.85rem;
	color: #ff4141;
}
