.container {
	font-size: 1.25rem;
	font-weight: 700;
	text-transform: uppercase;

	white-space: nowrap;

	display: grid;
	place-items: center;

	cursor: pointer;

	user-select: none;
}

.container.mini {
	font-size: 0.75rem;
}

.highlight {
	padding: 0.75rem 1.25rem;
	border-radius: 6rem;
}

.button {
	width: 4rem;
	height: 4rem;
	border-radius: 1rem;
	margin: 0 0.75rem;
}

.highlight,
.button {
	background: linear-gradient(-90deg, var(--transition-pink-blue));
	color: #4E575E;
}

.highlight:first-child,
.button:first-child {
	margin-left: 0;
}

.highlight:last-child,
.button:last-child {
	margin-right: 0;
}

.disabled {
	filter: saturate(0);
	cursor: not-allowed;
}

@media (max-width: 1280px ) and (min-width: 680px) {
	.container {
		font-size: 1rem;
	}
}

@media (max-width: 1080px) and (min-width: 680px) {
	.container {
		font-size: 0.8rem;
	}
}

@media (max-width: 680px) {
	.container {
		padding-left: 0;
		padding-right: 0;
	}

	.container:not(.highlight):not(.button) > *,
	.container:not(.highlight):not(.button) {
		width: 100%;
		text-align: left;
	}

	.highlight {
		padding: 1rem 2rem;
	}
	
	.highlight {
		max-width: fit-content;
	}
}

@media (max-width: 680px) {
	.container {
		font-size: 1rem;
	}
	
	.highlight {
		margin-right: 0;
		margin-left: 0;
	}
}


@media (max-width: 480px) {
	.container {
		font-size: 0.8rem;
	}
	
	.highlight {
		margin-right: 0;
		margin-left: 0;
	}
}
