@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;1,300;1,400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;1,200;1,300;1,400;1,600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Pangolin&display=swap);
:root {
	--highlight: #FBC0B1;
	--body-text: #4E575E;
	--light-gray: #DEDFE0;
	--light-blue: #EDF2F6;
	--blue: #dce8f2;
	--blue-gray: #cbd9e7;
	--dark-blue: #A8B6C4;
	--transition-pink-blue: #C2D3E1, #FDC0B0;
}

html,
body {
	width: 100%;
	height: 100%;
	padding: 0;
	margin: 0;
	color: #4E575E;
	color: var(--body-text);
	font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, sans-serif;
	scroll-behavior: smooth;
}

h1, h2, h3, h4, h5, h6, a {
	font-family: 'Nunito', sans-serif;
}

.appWrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
}

body {
	background: url(/static/media/section-background.108774dc.png) center center no-repeat fixed;
	background-size: cover;
}

a {
	color: inherit;
	text-decoration: none;
}

* {
	box-sizing: border-box;
}

.limited {
	width: 100%;
	max-width: 1680px;
	margin: 0 auto;
}

.limited-md {
	width: 100%;
	max-width: 1420px;
	margin: 0 auto;
}

main,
.hero {
	width: 100%;
}

.hero {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
}

.hero,
.viewport-100 {
	height: 100%;
	max-height: 1075px;
}

.hero-content {
	min-height: 300px;
	position: relative;
	display: flex;
	justify-content: space-between;
}

.title {
	margin: 3rem 0;
	margin-top: 0;
}

.page-heading {
	font-size: 3rem;
	font-weight: 700;
}

h1, h2 {
	font-weight: 500;
}

h1 {
	font-size: 4rem;
	margin: 1rem 0;
	color: #FBC0B1;
	color: var(--highlight);
}

h2 {
	font-size: 2rem;
	margin: 0;
}

h3 {
	font-size: 1.75rem;
	font-weight: 500;
}

.benefits {
	list-style: none;
	padding: 0;
	margin: 0;

	font-size: 1.25rem;
}

.benefits > li {
	margin: 1rem 0;
}

.benefits > li:first-child {
	margin-top: 0;
}

.benefits > li:last-child {
	margin-bottom: 0;
}

/* Treat Animation */

.treat-internal {
	transition: -webkit-filter 1s ease-in-out;
	transition: filter 1s ease-in-out;
	transition: filter 1s ease-in-out, -webkit-filter 1s ease-in-out;

	-webkit-animation: 6s bob infinite ease-in-out;

	        animation: 6s bob infinite ease-in-out;
}

.delay-1 {
	-webkit-animation-delay: -1.5s;
	        animation-delay: -1.5s;
}

.delay-2 {
	-webkit-animation-delay: -3s;
	        animation-delay: -3s;
}

.delay-3 {
	-webkit-animation-delay: -4.5s;
	        animation-delay: -4.5s;
}

.treat-image {
	-webkit-animation: rotate linear infinite;
	        animation: rotate linear infinite;
}

.delay-1 .treat-image {
	-webkit-animation-delay: -8s;
	        animation-delay: -8s;
	-webkit-animation-duration: 160s;
	        animation-duration: 160s;
}

.delay-2 .treat-image {
	-webkit-animation-delay: -12s;
	        animation-delay: -12s;
	-webkit-animation-duration: 80s;
	        animation-duration: 80s;
	animation-direction: reverse;
}

.delay-3 .treat-image {
	-webkit-animation-delay: -16s;
	        animation-delay: -16s;
	-webkit-animation-duration: 100s;
	        animation-duration: 100s;
	animation-direction: reverse;
}

.section-heading {
  font-size: 1.5rem;
	font-weight: 700;
  margin-bottom: 1.5rem;
}

.section-subheading {
	font-size: 1.25rem;
  margin: 1.25rem 0;
	font-weight: 700;
}

@-webkit-keyframes bob {
	0% {
		-webkit-filter: drop-shadow(6px 18px 16px #0303033D);
		        filter: drop-shadow(6px 18px 16px #0303033D);
		-webkit-transform: translateY(0);
		        transform: translateY(0);
	}
	50% {
		-webkit-filter: drop-shadow(6px 4px 6px #0303033D);
		        filter: drop-shadow(6px 4px 6px #0303033D);
		-webkit-transform: translateY(12px);
		        transform: translateY(12px);
	}
	100% {
		-webkit-filter: drop-shadow(6px 18px 16px #0303033D);
		        filter: drop-shadow(6px 18px 16px #0303033D);
		-webkit-transform: translateY(0);
		        transform: translateY(0);
	}
}

@keyframes bob {
	0% {
		-webkit-filter: drop-shadow(6px 18px 16px #0303033D);
		        filter: drop-shadow(6px 18px 16px #0303033D);
		-webkit-transform: translateY(0);
		        transform: translateY(0);
	}
	50% {
		-webkit-filter: drop-shadow(6px 4px 6px #0303033D);
		        filter: drop-shadow(6px 4px 6px #0303033D);
		-webkit-transform: translateY(12px);
		        transform: translateY(12px);
	}
	100% {
		-webkit-filter: drop-shadow(6px 18px 16px #0303033D);
		        filter: drop-shadow(6px 18px 16px #0303033D);
		-webkit-transform: translateY(0);
		        transform: translateY(0);
	}
}

@-webkit-keyframes rotate {
	0% {
		-webkit-transform: rotate(0deg);
		        transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(359deg);
		        transform: rotate(359deg);
	}
}

@keyframes rotate {
	0% {
		-webkit-transform: rotate(0deg);
		        transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(359deg);
		        transform: rotate(359deg);
	}
}

.centerLoading {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
}

.input {
  font-size: 1rem;
  font-family: inherit;
  padding: 0.5rem;
  border-radius: 8px;
  border: none;
  outline: none;

  background: var(--light-blue);
  resize: none;
	
	transition: 0.08s ease;
}

.input::-webkit-input-placeholder {
  color: var(--dark-blue);
}

.input:-ms-input-placeholder {
  color: var(--dark-blue);
}

.input::placeholder {
  color: var(--dark-blue);
}

.input:disabled {
	background: var(--blue-gray);
	cursor: not-allowed;
}

textarea.input {
  padding: 1rem;
}

.dark {
  background: var(--blue);
}

.mini {
  width: calc(1rem + 3rem);
  text-align: center;
}

.normal {
  padding: 1rem;
}

.big {
  padding: 1.5rem;
}

.textarea {
  width: 100%;
  min-height: 8rem;
}

.wide{
  width: 100%;
}

/* Buttons */
.button{
	--buttonAccentColor: var(--neutralAccentColor);
	color: white;
	border: none;
	background-color: var(--buttonAccentColor);
	padding: 15px 30px;
	border-radius: var(--smallRadius);
	cursor: pointer;
	outline: none !important;
	font-size: 18px;
	font-family: inherit;
	font-weight: bold;
}

.button.plain{
	color: inherit;
	background-color: transparent;
	font-size: inherit;
	padding: 0;
}

.button.plain.positive,
.button.plain.negative,
.button.plain.neutral{
	color: var(--buttonAccentColor);
}

.button:hover{
	-webkit-filter: brightness(115%);
	        filter: brightness(115%);
}

.button:active{
	-webkit-filter: brightness(95%);
	        filter: brightness(95%);
}

button.positive{
	--buttonAccentColor: var(--positiveAccentColor);
}

button.negative{
	--buttonAccentColor: var(--negativeAccentColor);
}

.button.hollow{
	border: 1px solid var(--buttonAccentColor);
	background-color: inherit;
	color: var(--buttonAccentColor);
}

.button:disabled{
	cursor: default;
	-webkit-filter: grayscale(70%) brightness(80%) opacity(80%);
	        filter: grayscale(70%) brightness(80%) opacity(80%);
}

.button.cion{
	font-weight: bold;
	background: linear-gradient(90deg, rgba(234,35,95,1) 0%, rgba(242,97,75,1) 100%);
}

.wide{
	width: 100%;
	min-width: 100%;
	max-width: 100%;
}

span.wide, label.wide{
	display: block;
}

textarea.input{
	min-height: 40px;
	font-family: inherit;
}

div.line{
	padding: 0;
	margin: 10px 0;
	border-top: 1px solid var(--borderColor);
}

.Anchor_container__RJNti {
	font-size: 1.25rem;
	font-weight: 700;
	text-transform: uppercase;

	white-space: nowrap;

	display: grid;
	place-items: center;

	cursor: pointer;

	-webkit-user-select: none;

	    -ms-user-select: none;

	        user-select: none;
}

.Anchor_container__RJNti.Anchor_mini__1pTpr {
	font-size: 0.75rem;
}

.Anchor_highlight__3_VrI {
	padding: 0.75rem 1.25rem;
	border-radius: 6rem;
}

.Anchor_button__xcA_x {
	width: 4rem;
	height: 4rem;
	border-radius: 1rem;
	margin: 0 0.75rem;
}

.Anchor_highlight__3_VrI,
.Anchor_button__xcA_x {
	background: linear-gradient(-90deg, var(--transition-pink-blue));
	color: #4E575E;
}

.Anchor_highlight__3_VrI:first-child,
.Anchor_button__xcA_x:first-child {
	margin-left: 0;
}

.Anchor_highlight__3_VrI:last-child,
.Anchor_button__xcA_x:last-child {
	margin-right: 0;
}

.Anchor_disabled__10zWj {
	-webkit-filter: saturate(0);
	        filter: saturate(0);
	cursor: not-allowed;
}

@media (max-width: 1280px ) and (min-width: 680px) {
	.Anchor_container__RJNti {
		font-size: 1rem;
	}
}

@media (max-width: 1080px) and (min-width: 680px) {
	.Anchor_container__RJNti {
		font-size: 0.8rem;
	}
}

@media (max-width: 680px) {
	.Anchor_container__RJNti {
		padding-left: 0;
		padding-right: 0;
	}

	.Anchor_container__RJNti:not(.Anchor_highlight__3_VrI):not(.Anchor_button__xcA_x) > *,
	.Anchor_container__RJNti:not(.Anchor_highlight__3_VrI):not(.Anchor_button__xcA_x) {
		width: 100%;
		text-align: left;
	}

	.Anchor_highlight__3_VrI {
		padding: 1rem 2rem;
	}
	
	.Anchor_highlight__3_VrI {
		max-width: -webkit-fit-content;
		max-width: -moz-fit-content;
		max-width: fit-content;
	}
}

@media (max-width: 680px) {
	.Anchor_container__RJNti {
		font-size: 1rem;
	}
	
	.Anchor_highlight__3_VrI {
		margin-right: 0;
		margin-left: 0;
	}
}


@media (max-width: 480px) {
	.Anchor_container__RJNti {
		font-size: 0.8rem;
	}
	
	.Anchor_highlight__3_VrI {
		margin-right: 0;
		margin-left: 0;
	}
}

.Breadcrumb_container__fPEmu {
  display: flex;
  align-items: center;

  font-size: 1.15rem;
  padding: 0 2rem;

  margin: 2rem 0;
}

.Breadcrumb_crumb__1vU3j:not(:last-child)::after {
  content: '>';
  margin: 0 1rem;
}

.Navigation_mobileLinks__28_9O {
	display: none;
}

.Navigation_links__2Ao6a {
	display: flex;
	grid-gap: 3rem;
	gap: 3rem;
	padding: 0.75rem 0;
	width: 100%;
}

.Navigation_links__2Ao6a > * {
	width: 100%;
	align-self: center;
}

.Navigation_buttons__4eMWg {
	display: flex;
	justify-content: flex-end;
}

@media (max-width: 1280px) {
	.Navigation_links__2Ao6a {
		justify-self: flex-end;
		grid-gap: 1.5rem;
		gap: 1.5rem;
	}
}

@media (max-width: 680px) {
	.Navigation_buttons__4eMWg {
		justify-content: center;
		align-self: center;
	}
	
	.Navigation_mobileLinks__28_9O {
		width: 100%;
		display: flex;
		justify-content: space-around;
	}

	.Navigation_mobileRow__2Vg58 {
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		align-items: center;
		grid-gap: 1.5rem;
		gap: 1.5rem;
	}

	.Navigation_mobileRow__2Vg58 .Navigation_buttons__4eMWg {
		align-self: flex-end;
	}
	
	.Navigation_links__2Ao6a:not(.Navigation_expanded__259nV) {
		display: none;
	}

	.Navigation_links__2Ao6a {
		margin-top: 2rem;
	}

	.Navigation_links__2Ao6a {
		flex-direction: column;
		align-self: center;
	}
}

.Header_container__1VC87 {
	width: 100%;
	padding: 2rem; 

	display: flex;
	justify-content: center;
	align-items: center;
	
	position: fixed;
	z-index: 1;
	top: 0;
	left: 0;
	right: 0;

	margin: 0 auto;

	background: rgba(255, 255, 255, 0.5);
	backdrop-filter: blur(4px);
	-webkit-backdrop-filter: blur(4px);
}

@supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
	.Header_container__1VC87 {
		background: rgba(255, 255, 255, 0.9);
	}
}

.Header_external__2Sv-O {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}

.Header_hamburger__uB-MN {
	display: none;

	outline: none;
	border: none;
	background: url(/static/media/hamburger.3c05c3a1.svg) no-repeat center center;
	background-size: contain;
	cursor: pointer;
}

.Header_logo__3_SJs {
	min-width: 200px;
	height: 80px;
	background: url(/static/media/logo-full.b722bae7.png) no-repeat left center;
	background-size: contain;
}

.Header_internal__3uFFP {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

@media (max-width: 978px) {	
	.Header_external__2Sv-O {
		display: flex;
		flex-direction: column;
		align-items: center;
		grid-gap: 2rem;
		gap: 2rem;
	}

	.Header_internal__3uFFP {
		width: 100%;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.Header_hamburger__uB-MN {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		margin: auto;

		height: 2rem;
		width: 2rem;
		display: none;
	}
	
	.Header_logo__3_SJs {
		background: url(/static/media/logo-single.4f06b1b7.png) no-repeat center center;
		background-size: contain;
	}
}

@media (max-width: 680px) {
	.Header_hamburger__uB-MN {
		display: block;
	}
}

.Input_input__3PNbO {
  font-size: 1rem;
  font-family: inherit;
  padding: 0.5rem;
  border-radius: 8px;
  border: none;
  outline: none;

  background: var(--light-blue);
  resize: none;
	
	transition: 0.08s ease;
}

.Input_input__3PNbO::-webkit-input-placeholder {
  color: var(--dark-blue);
}

.Input_input__3PNbO:-ms-input-placeholder {
  color: var(--dark-blue);
}

.Input_input__3PNbO::placeholder {
  color: var(--dark-blue);
}

.Input_input__3PNbO:disabled {
	background: var(--blue-gray);
	cursor: not-allowed;
}

textarea.Input_input__3PNbO {
  padding: 1rem;
}

.Input_dark__2KqW0 {
  background: var(--blue);
}

.Input_mini__30EC6 {
  width: calc(1rem + 3rem);
  text-align: center;
}

.Input_normal__1Bb05 {
  padding: 1rem;
}

.Input_big__24l30 {
  padding: 1.5rem;
}

.Input_textarea__h_Kof {
  width: 100%;
  min-height: 8rem;
}

.DeliveryInfo_container__24r0C {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mobile.DeliveryInfo_container__24r0C {
  margin-top: 6rem;
}

@media (max-width: 680px) {
  .mobile.DeliveryInfo_container__24r0C {
    margin-top: 0rem;
  }
}


.DeliveryInfo_breadcrumb__1Tfjq {
  align-self: flex-start;
}

.DeliveryInfo_deliveryInfoContainer__10MRm {
  padding: 8rem 2rem;
}

.mobile .DeliveryInfo_deliveryInfoContainer__10MRm {
  padding: 6rem 2rem;
}

.mobile .DeliveryInfo_deliveryInfoContainer__10MRm {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  width: 100%;
}

.DeliveryInfo_inputRow__3VWC5 {
  display: flex;
  padding: 1rem 0;
  grid-gap: 1rem;
  gap: 1rem;
}

.mobile .DeliveryInfo_inputRow__3VWC5 {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.mobile .DeliveryInfo_inputRow__3VWC5 > * {
  display: flex;
  flex-direction: column;
  width: 100%;
}



.DeliveryInfo_nextContainer__1JSA8 {
  margin-top: 1rem;
  max-width: 200px;
}


.mobile .DeliveryInfo_nextContainer__1JSA8, .mobile .DeliveryInfo_nextContainer__1JSA8 > * {
  max-width: 100%;
  width: 100%;
}

.DeliveryInfo_issueText__2UWPG {
	margin-top: 0.75rem;
}

.DeliveryInfo_issueText__2UWPG,
.DeliveryInfo_issueList__3bxxE {
	font-size: 0.75rem;
	color: #ff4141;
}

ul.DeliveryInfo_issue-list__1WJPa {

}

.DeliveryInfo_deliveryWindow__3gULf > div {
  font-size: 1rem !important;
  padding: 1rem;
  color: black;
}

.DeliveryInfo_deliveryWindow__3gULf{
  width: 33% !important;
}

.mobile .DeliveryInfo_deliveryWindow__3gULf{
  width: 100% !important;
}

.CustomCookie_container__3G6S8 {
  padding: 2rem;
  font-size: 8rem;
}

.CustomCookie_container__3G6S8 img {
  max-width:  500px;
  object-fit: contain;
}

.CustomCookie_text__3vrgO,
.CustomCookie_offsetText__1KNhO {
	display: flex;
	justify-content: center;
	align-items: center;
	
  position: absolute;
  inset: 0;
	margin: 0;

  font-family: 'Pangolin';
  text-align: center;
}

.CustomCookie_text__3vrgO {
  z-index: 3;
}

.CustomCookie_offsetText__1KNhO {
  z-index: 2;
  -webkit-transform: translate(4px, 4px);
          transform: translate(4px, 4px);
  -webkit-filter: brightness(0.7);
          filter: brightness(0.7);
}

.Dropdown_container__MQRTf {
  position: relative;

  padding: 0.75rem;
  font-size: 1.25rem;
  
  background: var(--light-blue);
  border-radius: 8px;

  -webkit-user-select: none;

      -ms-user-select: none;

          user-select: none;
}

.Dropdown_preview__1-IdJ {
  margin-right: 2.5rem;
}

.Dropdown_container__MQRTf.Dropdown_open__3Ctrs {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.Dropdown_icon__3VVFZ {
  position: absolute;
  right: 1rem;
  top: 0;
  bottom: 0;

  margin: auto;

  transition: 0.16s ease;
}

.Dropdown_container__MQRTf.Dropdown_open__3Ctrs .Dropdown_icon__3VVFZ {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  -webkit-transform-origin: center 50%;
          transform-origin: center 50%;
}

.Dropdown_container__MQRTf:hover {
  cursor: pointer;
}

.Dropdown_content__1bUId {
  position: absolute;

  left: 0;
  right: 0;
  bottom: 0;

  padding: 0.25rem;
  border-radius: 0 0 8px 8px;

  -webkit-transform: translateY(100%);

          transform: translateY(100%);
  background: var(--light-blue);
  -webkit-filter: brightness(0.975);
          filter: brightness(0.975);
  
  max-height: 400px;
  overflow-y: auto;
}

.Dropdown_option__3WSwf {
  margin: 0.125rem;
  padding: 0.5rem;
}

.Dropdown_option__3WSwf:hover {
  cursor: pointer;
  -webkit-filter: brightness(0.965);
          filter: brightness(0.965);
  background: inherit;
  border-radius: 4px;
}

.OptionContainer_container__1kOyX {
  background: var(--blue);
  padding: 2rem;
  border-radius: 0.5rem;
}

.OptionContainer_heading__kNBgs {
  font-size: 1.4rem;
  margin-bottom: 1rem;
}

.OptionContainer_grid__1Qluc {
  display: grid;
  grid-template-columns: repeat(5, minmax(auto, 1fr));
  grid-template-rows: auto;
  grid-gap: 1rem;
  gap: 1rem;
}

.mobile .OptionContainer_grid__1Qluc {
  display: flex;
  flex-direction: column;
}

.mobile .OptionContainer_grid__1Qluc > * {
  font-size: 1rem;
}

.OptionContainer_error__2-NTV {
	font-size: 0.75rem;
	margin-bottom: 1.5rem;
	color: #ff4141;
}

.OrderTotal_row__2H8Vm {
  display: flex;
  justify-content: space-between;

  font-size: 1.5rem;
}

.OrderTotal_row__2H8Vm:not(:first-child) {
  margin-top: 0.5rem;
}

.OrderTotal_value__1xa3U {
  font-weight: 700;
}

.Selector_option__3gY8e {
  display: flex;
  align-items: center;

  color: var(--body-text);
  font-size: 0.8rem;

	-webkit-user-select: none;

	    -ms-user-select: none;

	        user-select: none;
}

.Selector_option__3gY8e:hover {
  cursor: pointer;
}

.Selector_selector__2XaY4 {
 	min-width: 1.2rem;
  height: 1.2rem;
  margin-right: 0.4rem;

  background: white;
  border-radius: 4px;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 0.8rem;
  color: var(--body-text);
}

.Selector_selector__2XaY4.Selector_radio__2YqT2 {
  border-radius: 50%;
}

.Selector_selector__2XaY4.Selector_radio__2YqT2 .Selector_check__1-AAJ {
  background: var(--body-text);
  border-radius: 50%;
  width: 100%;
  height: 100%;
  -webkit-transform: scale(0.6);
          transform: scale(0.6);
}

.Selector_disabled__15XXx {
  background: var(--light-blue);
}

.Selector_disabled__15XXx + .Selector_label__3awEe {
	color: var(--dark-blue);
}

.Selector_check__1-AAJ {
  display: none;
}

.Selector_selected__hfPaz .Selector_check__1-AAJ {
  display: block;
}

.Selector_optionIcon__ixmwN {
	margin-left: 0.25rem;
	width: 1.5rem;
	height: 1.5rem;
	aspect-ratio: 1 / 1;
}

.BuildACookie_container__21HoZ {
  flex-grow: 1;
}


.mobile.BuildACookie_container__21HoZ {
  margin-top: 8rem;
}

@media (max-width: 680px) {
  .mobile.BuildACookie_container__21HoZ {
    margin-top: 0rem;
  }
}


.BuildACookie_builder__3U0M- {
  display: flex;
  width: 100%;

  padding: 4rem 2rem;
}

.BuildACookie_options__1MY2z {
  flex-grow: 1;
}

.BuildACookie_row__LB1Lm {
  display: flex;
  grid-gap: 1.5rem;
  gap: 1.5rem;
}

.mobile .BuildACookie_row__LB1Lm {
  flex-direction: column;
}


.BuildACookie_row__LB1Lm:not(:first-child) {
  margin-top: 1.5rem;
}

.BuildACookie_row__LB1Lm > * {
  flex-grow: 1;
}

.BuildACookie_cookieContainer__p3xXT {
  align-self: flex-start;
  position: sticky;
  top: 10rem;
  
  margin-right: 2rem;
}

.BuildACookie_totalContainer__1fE9O {
  display: flex;
  padding: 3rem 0;
  justify-content: flex-end;
}

.BuildACookie_lettering__3HwiY {
  display: flex;
	flex-direction: column;
	grid-gap: 1rem;
	gap: 1rem;
}

.BuildACookie_flavours__1oTKr {
	flex-grow: 1;
	display: grid;
	grid-template-columns: repeat(4, auto);
}

.mobile .BuildACookie_flavours__1oTKr {
  display: flex;
  flex-direction: column;
}

.mobile .BuildACookie_flavours__1oTKr > * {
  margin: 0.3rem 0;
  font-size: 16px;
}

.BuildACookie_internalGroup__3RMWU {
  display: flex;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
}

.mobile .BuildACookie_internalGroup__3RMWU {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.mobile .BuildACookie_internalGroup__3RMWU > * {
  align-self: flex-start;
  margin: 0.1rem 0;
}

.mobile .BuildACookie_internalGroup__3RMWU > input {
  width: 100%;
}

.BuildACookie_internalTitle__10umz {
  font-size: 1.35rem;
}

.BuildACookie_submitButton__28mHr {
	margin-left: auto;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
}

@media (max-width: 1320px) {
  .BuildACookie_cookieContainer__p3xXT {
    position: relative;
    max-width: 300px;
    margin-right: 0;

    align-self: unset;
    top: unset;
  }

  .BuildACookie_cookieContainer__p3xXT img {
    max-width: 100%;
  }

  .BuildACookie_builder__3U0M- {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .BuildACookie_options__1MY2z {
    width: 100%;
    overflow-y: auto;
  }
}

.mobile .BuildACookie_totalContainer__1fE9O, .mobile .BuildACookie_totalContainer__1fE9O > * {
  width: 100% !important;
  justify-content: flex-start;
}


.mobile .BuildACookie_submitButton__28mHr, .mobile .BuildACookie_submitButton__28mHr > * {
  width: 100% !important;
  min-width: 100%;
  /* justify-content: flex-start; */
}

.BuildACookie_error__1GBzX {
	max-width: 64ch;
	text-align: right;
	margin-left: auto;

	margin-bottom: 1.5rem;

	font-size: 0.85rem;
	color: #ff4141;
}

.CartItem_container__3fETS {
  display: flex;
  align-items: space-between;
  align-items: center;
}

.CartItem_image__2jPPv {
  width: 3rem;
  height: 3rem;
  border-radius: 0.5rem;
  margin-right: 0.5rem;

  object-fit: cover;
}

.CartItem_price__1JWf1 {
  font-weight: 700;
}

.CartItem_itemInfo__3-Plu {
  display: flex;
}

.CartItem_removeBtn__27YVz{
  flex-grow: 1;
  text-align: right;
  transition: 0.3s;
}


.CartItem_removeBtn__27YVz:hover{
  cursor: pointer;
  color: #8f3924;
}

.CartItem_options__2WD8x {
	margin: 0.25rem 0;
	font-size: 0.75rem;
}

.MyOrder_container__1XCi8 {
  width: 100%;
}

.MyOrder_cart__12qkm {
  padding: 1rem;
  border-radius: 0.5rem;

  background: var(--blue);

	display: flex;
	flex-direction: column;
	grid-gap: 0.5rem;
	gap: 0.5rem;
}

.MyOrder_total__21vny {
	margin-top: 2rem;
	display: flex;
	flex-direction: column;
}

.MyOrder_row__16Ur4:not(:last-child) {
	margin-bottom: 0.25rem;
}

.MyOrder_row__16Ur4 {
	display: flex;
	justify-content: space-between;
}

.MyOrder_dataValue__3VPm7 {
	font-weight: 700;
}

.MyOrder_submitButton__38vaI {
	margin-top: 6rem;
}

.mobile .MyOrder_submitButton__38vaI, .mobile .MyOrder_submitButton__38vaI > * {
	margin-top: 2rem;
	width: 100%;
	min-width: 100%;
}

.MyOrder_emptyState__2IvLD {
	padding: 1rem;
	display: flex;
	justify-content: center;
	align-items: center;

	font-size: 1.1rem;
	opacity: 0.8;
}


.MyOrder_errorMsg__11r5n {
	color: red;
	margin-top: 0.5rem;
}

.CustomOrderItem_container__2tW88 {
  max-width: 142px;
  
  display: flex;
  flex-direction: column;
  align-items: center;

  -webkit-user-select: none;

      -ms-user-select: none;

          user-select: none;
}

.CustomOrderItem_preview__lizRF {
  cursor: pointer;

  height: 142px;
  width: 142px;

  position: relative;
  border-radius: 0.5rem;
  overflow: hidden;
}

.CustomOrderItem_preview__lizRF:hover::before {
  content: '';
  background: rgba(0, 0, 0, 0.25);
  position: absolute;
  inset: 0;
}

.CustomOrderItem_hover__140gu {
  display: none;

  width: 100%;
  height: 100%;
  place-items: center;
}

.CustomOrderItem_preview__lizRF:hover .CustomOrderItem_hover__140gu {
  display: grid;
  position: absolute;
  inset: 0;

  z-index: 2;
}

.CustomOrderItem_image__3KhJd {
  width: 100%;
  height: 100%;

  object-fit: cover;
}

.CustomOrderItem_name__GRWCm {
  text-align: center;
  margin-top: 0.5rem;
}

.CustomOrderItem_price__3GP03 {
  font-weight: 700;
}

.popupBG{
	position: fixed;
	background-color: rgba(32, 32, 49, 0.3);
	min-height: 100vh;
	width: 100vw;
	-webkit-backdrop-filter: blur(10px);
	        backdrop-filter: blur(10px);
	cursor: pointer;
	padding: 32px;

	max-height: 100vh;
	overflow-y: auto;
	display: flex;
	align-items: center;
	justify-content: center;
}

.Popup{
	background-color: #EDF2F6;
	border-radius: 20px;
	cursor: auto;
	padding: 20px;
}

.Popup {
	max-height: 90vh;
	overflow: auto;
}

.Popup h1.popTitle {
	font-size: 33pt;
	color: #60686E;
}

.Popup .titleBar {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px 10px 25px 10px;
	grid-gap: 25px;
	gap: 25px;
	border-bottom: 2px solid #C4C9CF;
}

.Popup h1:empty{
	display: none;
}

.Popup h1{
	margin: 0;
}

.Popup .exitIcon {
	font-size: 35px;
	color: #A2A2AF;
}

.Popup .story{
	padding: 10px 0;
}

.Popup .story:empty{
	display: none;
}

.mobile .popupBG{
	padding: 0;
}

.mobile .Popup{
	height: 100%;
	max-height: unset;
	
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	overflow-y: auto;
	border-radius: 0;

	padding: 20px;
}

.AddToCartPopup_AddToCartPopup__1M7TA{
	max-width: 500px;
	font-size: 16pt;
	padding: 20px;
	color: #60686E;
}

.AddToCartPopup_image__1WXbG{
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 20px 0;
}

.AddToCartPopup_image__1WXbG img{
	height: 200px;
	border-radius: 10px;
}

.AddToCartPopup_optionCheckRow__3KSzx{
	padding: 5px 0;
	cursor: pointer;
}

.AddToCartPopup_optionCheckLabel__2vVZW{
	padding-left: 10px;
}

.AddToCartPopup_disabled__3I8Nt{
	-webkit-filter: opacity(50%);
	        filter: opacity(50%);
}

.AddToCartPopup_check__3AnZ8{
	-webkit-transform: scale(1.2);
	        transform: scale(1.2);
	border: none;
	outline: none;
}

.AddToCartPopup_story__3f0yG{
	padding: 10px 0;
}

.AddToCartPopup_MultiOptionRow__2iHBJ{
	padding-top: 20px;
	padding-bottom: 30px;
	display: flex;
}

.AddToCartPopup_MultiOptionRow__2iHBJ .AddToCartPopup_numberContainer__jhdTZ{
	width: 30px;
	-webkit-transform: translateY(8px);
	        transform: translateY(8px);
}

.AddToCartPopup_MultiOptionRow__2iHBJ .AddToCartPopup_notNumberContainer__OK7g-{
	width: calc(100% - 60px);
}

.AddToCartPopup_selector__12Frw{
	background-color: #FFFFFF;
}

.AddToCartPopup_verticalLine__2f0yU{
	width: 50%;
	border-right: 3px solid #C5D5E2;
	height: 16px;
}

.AddToCartPopup_center__xQlKy{
	justify-content: center;
}

.OrderItem_container__245A6 {
  max-width: 142px;
  
  display: flex;
  flex-direction: column;
  align-items: center;

  -webkit-user-select: none;

      -ms-user-select: none;

          user-select: none;
}

.OrderItem_preview__2Kjs9 {
  cursor: pointer;

  height: 142px;
  width: 142px;

  position: relative;
  border-radius: 0.5rem;
  overflow: hidden;
}

.OrderItem_preview__2Kjs9:hover::before {
  content: '';
  background: rgba(0, 0, 0, 0.25);
  position: absolute;
  inset: 0;
}

.OrderItem_hover__3VgPw {
  display: none;

  width: 100%;
  height: 100%;
  place-items: center;
}

.OrderItem_preview__2Kjs9:hover .OrderItem_hover__3VgPw {
  display: grid;
  position: absolute;
  inset: 0;

  z-index: 2;
}

.OrderItem_image__1Ps2e {
  width: 100%;
  height: 100%;

  object-fit: cover;
}

.OrderItem_name__2kgyS {
  text-align: center;
  margin-top: 0.5rem;
}

.OrderItem_price__2Hh1o {
  font-weight: 700;
}

.OrderItemGroup_container__2x_Km:not(:first-child) {
  margin-top: 2rem;
}

.OrderItemGroup_heading__2SZOr {
  margin-bottom: 1.5rem;
  font-size: 2rem;
  font-weight: 700;
}

.OrderItemGroup_items__1BGg3 {
  display: flex;
  flex-wrap: wrap;

  grid-gap: 1.5rem;

  gap: 1.5rem;
  grid-row-gap: 4rem;
  row-gap: 4rem;

  width: 100%;
}

.mobile .OrderItemGroup_items__1BGg3 {
  grid-row-gap: 1rem;
  row-gap: 1rem;
  justify-content: center;
}

.Order_container__1FM7j {
  flex-grow: 1;

  padding: 2rem 0;
}

.mobile.Order_container__1FM7j {
  margin-top: 6rem;
}

@media (max-width: 680px) {
  .mobile.Order_container__1FM7j {
    margin-top: 0rem;
  }
}


.Order_split__EV8X7 {
  display: grid;
  grid-template-columns: 65% 1fr;
  grid-gap: 2rem;
  gap: 2rem;
	padding: 0 2rem;
}

.Order_nextButton__h041J {
  margin-top: 1rem;
  max-width: 220px;
}

.mobile .Order_nextButton__h041J {
  width: 100%;
  max-width: 100%;
}

.mobile .Order_nextButton__h041J > a {
  min-width: 100%;
}

.mobile .Order_split__EV8X7 {
  display: flex;
  flex-direction: column;
  min-width: 100vw !important;
}

.CustomerInfo_title__3IcVt {
	margin-bottom: 0.5rem;
	font-size: 0.85rem;
}

.CustomerInfo_dataPiece__xsCK4 {
	font-size: 1.1rem;
}

.CustomerInfo_dataPiece__xsCK4:not(:last-child) {
	margin-bottom: 0.25rem;
}

.Review_container__2r9m8 {
  flex-grow: 1;

  padding: 2rem 0;
}

.mobile.Review_container__2r9m8 {
  margin-top: 6rem;
}

@media (max-width: 680px) {
  .mobile.Review_container__2r9m8 {
    margin-top: 0rem;
  }
}



.Review_split__3Zd2B {
  display: grid;
  grid-template-columns: 65% 1fr;
  grid-gap: 2rem;
  gap: 2rem;
	padding: 0 2rem;
}



.mobile .Review_split__3Zd2B {
  display: flex;
  flex-direction: column;
  min-width: 100vw !important;
}


.Review_quantityDisplay__2p6lk {
	margin-bottom: 0.75rem;
}

.Review_customerInfo__1513G {
	display: grid;
	grid-template-columns: auto auto;
	grid-template-rows: auto auto;

	padding: 4rem;
	grid-gap: 2rem;
	gap: 2rem;
}


.mobile .Review_customerInfo__1513G {
	display: flex;
	flex-direction: column;
	width: 100%;
	padding-left: 0;
	padding-bottom: 1rem;
}

.Complete_container__2D8yL {
  flex-grow: 1;

  padding: 2rem 0;
}

.Complete_split__31wl6 {
  display: grid;
  grid-template-columns: 65% 1fr;
  grid-gap: 2rem;
  gap: 2rem;
	padding: 0 2rem;
}

.Complete_quantityDisplay__pVO_p {
	margin-bottom: 0.75rem;
}

.Complete_customerInfo__3koAX {
	display: grid;
	grid-template-columns: auto auto;
	grid-template-rows: auto auto;

	padding: 4rem;
	grid-gap: 2rem;
	gap: 2rem;
}

.Complete_heading__18AO6 {
	font-size: 2.5rem;
	margin: auto;
	align-self: center;
	margin-bottom: 4rem;
}

.Complete_content__2-2du {
	margin: auto;
	
	max-width: 1000px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	padding: 2rem;

	line-height: 1.75em;
	font-size: 1.25rem;
}

.Complete_anchorContainer__1EKYW {
	margin-top: 2rem;
	width: 100%;
	display: flex;
	justify-content: center;
}

.Complete_toHome__29fFX {
	width: 16rem;
	
	justify-self: center;
}


.mobile .Complete_anchorContainer__1EKYW, .mobile .Complete_anchorContainer__1EKYW > * {
	width: 100%;
	max-width: 100%;
}

