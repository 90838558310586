.AddToCartPopup{
	max-width: 500px;
	font-size: 16pt;
	padding: 20px;
	color: #60686E;
}

.image{
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 20px 0;
}

.image img{
	height: 200px;
	border-radius: 10px;
}

.optionCheckRow{
	padding: 5px 0;
	cursor: pointer;
}

.optionCheckLabel{
	padding-left: 10px;
}

.disabled{
	filter: opacity(50%);
}

.check{
	transform: scale(1.2);
	border: none;
	outline: none;
}

.story{
	padding: 10px 0;
}

.MultiOptionRow{
	padding-top: 20px;
	padding-bottom: 30px;
	display: flex;
}

.MultiOptionRow .numberContainer{
	width: 30px;
	transform: translateY(8px);
}

.MultiOptionRow .notNumberContainer{
	width: calc(100% - 60px);
}

.selector{
	background-color: #FFFFFF;
}

.verticalLine{
	width: 50%;
	border-right: 3px solid #C5D5E2;
	height: 16px;
}

.center{
	justify-content: center;
}
