.option {
  display: flex;
  align-items: center;

  color: var(--body-text);
  font-size: 0.8rem;

	user-select: none;
}

.option:hover {
  cursor: pointer;
}

.selector {
 	min-width: 1.2rem;
  height: 1.2rem;
  margin-right: 0.4rem;

  background: white;
  border-radius: 4px;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 0.8rem;
  color: var(--body-text);
}

.selector.radio {
  border-radius: 50%;
}

.selector.radio .check {
  background: var(--body-text);
  border-radius: 50%;
  width: 100%;
  height: 100%;
  transform: scale(0.6);
}

.disabled {
  background: var(--light-blue);
}

.disabled + .label {
	color: var(--dark-blue);
}

.check {
  display: none;
}

.selected .check {
  display: block;
}

.optionIcon {
	margin-left: 0.25rem;
	width: 1.5rem;
	height: 1.5rem;
	aspect-ratio: 1 / 1;
}
