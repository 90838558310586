.container {
  max-width: 142px;
  
  display: flex;
  flex-direction: column;
  align-items: center;

  user-select: none;
}

.preview {
  cursor: pointer;

  height: 142px;
  width: 142px;

  position: relative;
  border-radius: 0.5rem;
  overflow: hidden;
}

.preview:hover::before {
  content: '';
  background: rgba(0, 0, 0, 0.25);
  position: absolute;
  inset: 0;
}

.hover {
  display: none;

  width: 100%;
  height: 100%;
  place-items: center;
}

.preview:hover .hover {
  display: grid;
  position: absolute;
  inset: 0;

  z-index: 2;
}

.image {
  width: 100%;
  height: 100%;

  object-fit: cover;
}

.name {
  text-align: center;
  margin-top: 0.5rem;
}

.price {
  font-weight: 700;
}
