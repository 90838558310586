.title {
	margin-bottom: 0.5rem;
	font-size: 0.85rem;
}

.dataPiece {
	font-size: 1.1rem;
}

.dataPiece:not(:last-child) {
	margin-bottom: 0.25rem;
}
