.input {
  font-size: 1rem;
  font-family: inherit;
  padding: 0.5rem;
  border-radius: 8px;
  border: none;
  outline: none;

  background: var(--light-blue);
  resize: none;
	
	transition: 0.08s ease;
}

.input::placeholder {
  color: var(--dark-blue);
}

.input:disabled {
	background: var(--blue-gray);
	cursor: not-allowed;
}

textarea.input {
  padding: 1rem;
}

.dark {
  background: var(--blue);
}

.mini {
  width: calc(1rem + 3rem);
  text-align: center;
}

.normal {
  padding: 1rem;
}

.big {
  padding: 1.5rem;
}

.textarea {
  width: 100%;
  min-height: 8rem;
}
